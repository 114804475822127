import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Toggle",
  "type": "Form",
  "icon": "settings01Line"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Toggles should be used when there is a setting that needs to be adjusted without having
an additional step by the user, except switching the toggle. Toggle is not part of a form
with a submit button, but it rather `}<strong parentName="p">{`acts like a checkbox, and a submit button
simultaneously`}</strong>{`.`}</p>
    <p>{`Toggles should not be used for multi-selection screen. For that purpose a checkbox
should be used.`}</p>
    <hr></hr>
    <blockquote>
      <p parentName="blockquote">{`Regarding the difference between a toggle and a checkbox :`}</p>
    </blockquote>
    <p>{`Switches are the preferred way to adjust settings on mobile. The option that the`}</p>
    <blockquote>
      <p parentName="blockquote">{`switch controls, as well as the state it’s in, should be made clear from the
corresponding inline label.`}</p>
    </blockquote>
    <h2>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=false center=true",
        "live": "true",
        "editor": "false",
        "center": "true"
      }}>{`<Toggle />
`}</code></pre>
    <h2>{`Variations`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<div style={{ width: "200px", display: "flex", alignItems: "center" }}>
  <Toggle defaultChecked />
  <Separator />
  <Toggle />
  <Separator />
  <Toggle defaultChecked disabled />
  <Separator />
  <Toggle defaultChecked={false} disabled />
</div>
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`The behaviour of the text in the Toggle might be subject of another implementation,
since having the font weight changed over the state, might increase the width, which
is probably undesirable`}</p>
    </blockquote>
    <h2>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`checked`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether the prop type was checked or not`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The toggle will be disabled if set to `}<inlineCode parentName="td">{`true`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onChange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback for when the toggle was toggled`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      